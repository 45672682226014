export class PredictionGroupConfig {
    groupName: string;
    min: number;
    max: number;
    minThresholds: Array<number>;
    collectionPath: string;
    
    constructor(
        groupName: string,
        min: number,
        max: number,
        minThresholds: Array<number>,
        collectionPath: string,
    ) {
        this.groupName = groupName;
        this.min = min;
        this.max = max;
        this.minThresholds = minThresholds;
        this.collectionPath = collectionPath
    }
}
