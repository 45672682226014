import firebase from 'firebase/compat/app';

export class RecentReviewListItem {
    claim: string;
    createdAt: firebase.firestore.Timestamp;
    updatedAt: firebase.firestore.Timestamp;
    reviewBatchId: string;
    uid: string;
    reviewProcessStartedBy: string;
    recentReviewRecordId:string;
    auditlistId:string;
}