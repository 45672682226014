import { UserRoles } from "./user-roles";

export class UserData {
    uid: string;
    email: string;
    fullname: string;
    userRoles: UserRoles;
    orgGroups: Array<string>;

    constructor(uid, fullname, email, userRoles, orgGroups) {
        this.uid = uid;
        this.email = email;
        this.fullname = fullname;
        this.userRoles = userRoles;
        this.orgGroups = orgGroups;
    }
}
