import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { HelperService } from 'src/app/services/helper-service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  navigateTo(root) {
    if (root === 'reports') {
      const userRoles = this.authService.userRoles;
      const userRolesList = Object.keys(userRoles).filter(i => userRoles[i]);
      const defaultPath = this.helperService.getDefaultReportPath(userRolesList);
      this.router.navigate([defaultPath]); //once logged in traverse to reports path
    } else if (root === 'data-management') {
      this.router.navigate(['data-management/claimant-listing-pipeline']);
    } else {
      this.router.navigate([root]); //once logged in traverse to reports path
    }
   
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
