import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
    @Input() buttonId: string;
    @Input() buttonText: string;
    @Input() buttonIcon: string;
    @Input() buttonType: 'button' | 'submit' | 'reset' | 'file' = 'button';
    @Input() isDisabled: boolean;
    @Input() highlightButton: boolean;
    @Input() multipleFile: boolean = false;
    @Output() clickEvent = new EventEmitter<any>();
    files: File[] | null = null;

    constructor() { }

    ngOnInit(): void { }

    clickButton(id: string, files: File[]) {
        this.clickEvent.emit({id: id, files: files});
    }

    selectFiles(id: string, event: any): void {
        this.files = event.target.files;
        this.clickButton(id, this.files);
        event.target.value = '';
    }

}
