import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
    title: string = '';
    message: string = '';
    buttons: Array<any> = [];
    confirmButton: string = '';

    constructor(
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data)
    {
        this.title = data.title;
        this.message = data.message;
        this.buttons = data.buttons;
        this.confirmButton = data.confirmButton;
    }

    ngOnInit(): void { }

    hasConfirm(button: string): boolean {
        if (button === this.confirmButton) {
            return true;
        } else {
            return false;
        }
    }

    confirm(button: string) {
        const confirm = this.hasConfirm(button);
        this.close(confirm);
    }

    close(confirm: boolean = false) {
        this.dialogRef.close({confirm: confirm});
    }

}
