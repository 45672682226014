import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadScreenComponent } from './load-screen.component';
import { LoadScreenRoutingModule } from './load-screen-routing.module';

@NgModule({
  declarations: [
    LoadScreenComponent
  ],
  imports: [
    CommonModule,
    LoadScreenRoutingModule
  ],
  exports: [
    LoadScreenComponent
  ]
})
export class LoadScreenModule { }
