import { Component } from '@angular/core';
import { AuthService } from '../../../core/auth.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  constructor(
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }
  
  logout() {
    this.googleAnalyticsService.sendEventToAnalytics('web_app_logout','authentication', 'user logged out');
    this.authService.logout();
  }
}
