import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QuarterCalculatorService {

    constructor() { }

    getQuarter(date: Date): number {
        const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
        return Math.ceil(month / 3);
    }
    
}
