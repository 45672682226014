import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { LossReserveAuthorityService  } from './loss-reserve-authority.service';

import { Observable, of, Subscription } from 'rxjs';
import { 
    map,
    switchMap,
    tap,
    take,
    mergeAll,
    mergeMap,
} from 'rxjs/operators';

import { 
    AngularFirestore,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import * as echarts from 'echarts';
type ECharts = echarts.ECharts;
type EChartsOption = echarts.EChartsOption;


@Component({
  selector: 'app-loss-reserve-authority',
  templateUrl: './loss-reserve-authority.component.html',
  styleUrls: ['./loss-reserve-authority.component.css']
})
export class LossReserveAuthorityComponent implements OnInit {
    metrics = [];
    @ViewChild('lossReserveChart') lossReserveChart: ElementRef;
    echart: ECharts;

    constructor(
        private afs: AngularFirestore,
        private lossReserveAuthService: LossReserveAuthorityService,
    ) {
        this.afs.collection(`adjustors`)
        .valueChanges({idField: 'id' })
        .pipe(
            take(1),
            map(adjustors => adjustors.map(adjustor => adjustor.id)),
            mergeAll(),
            mergeMap((id: any) => {
                // console.log('CLAIMANT-REPORTS CLAIMANT-REPORTS CLAIMANT-REPORTS');
                // console.log(id);
                // console.log('CLAIMANT-REPORTS CLAIMANT-REPORTS CLAIMANT-REPORTS');
                return this.afs.collection(`adjustors/${id}/claimant-reports`).valueChanges();
            }),
            tap(claimantReports => {
                // console.log('WTF RDO RESULTS LOOK A LIKE ');
                // console.log(claimantReports);
                // console.log('WTF RDO RESULTS LOOK A LIKE ');
            }),
            map(results => {
                // this.lossReserveAuthService.initAdjustorLossReserveMetrics(results);
                return results;
            }),
            tap(results => {}),
        ).subscribe(
            r => {
                console.log('******* ADJUSTOR METRICS ******* *******');
                // console.log(this.lossReserveAuthService.adjustorMetrics);
                console.log('******* ADJUSTOR METRICS ******* *******');
        });
    }

    ngOnInit(): void {
    }

}
