// Import the functions you need from the SDKs you need

export const environment = {
    production: true
};
  
export const firebaseConfig = {
  apiKey: "AIzaSyCJcpEFNiCckzv0I9wSOIRiFya-fdmeHd0",
  authDomain: "comm-vehicle-data-client-1.firebaseapp.com",
  projectId: "comm-vehicle-data-client-1",
  storageBucket: "comm-vehicle-data-client-1.appspot.com",
  messagingSenderId: "246228937640",
  appId: "1:246228937640:web:61c6ddc015c18a7dba4b9d",
  measurementId: "G-VSL4XB79NV"
};

export const coolBeans = {
  bimBam: "AIzaSyDmWoueUpo8gHsri9zNueSm3rYWSHtR9l8"
};

export const recaptchav2 = {
  siteKey:'6LfTUBIgAAAAAJnrGcltCwd29Y8VF_YSHrKGzVyy',
  secretKey:'6LfTUBIgAAAAAOm6hSEHDthZyblkNpYyM6x9Qu3E',
};

export const recaptchav3 = {
  siteKey:'6LeBfAcgAAAAAF5lzYGvM_iIVUy6FqAt5wsim01g',

};
  