export class RecordIdModelConfig {
    answersCollectionId:string;
    notesCollectionId:string;
    questionsCollectionId:string;
    lossReserveSummariesId:string;
    lossReserveAllClosedClaimsSum:string;
    lossReserveAllOpenClaimsSum:string;
    mostRecentCollectionId:string;
    supersetUrl:string;
    embeddedDashboardId:string;
    tokenApiUrl:string;
}