import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { gtag } from '../../assets/gtag';
import { AuthService } from '../core/auth.service';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    constructor(private afs: AngularFirestore,
                private authService: AuthService
    ) { 

    }

    sendEventToAnalytics(event_name,category,label){
        let userFullName = '';
        if (this.authService.userData){
            userFullName = this.authService.userData.fullname;
        }
        gtag('event', event_name, {
            'event_category': category,
            'event_label': label,
            'value':userFullName
        });
    }
    
}