import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
    private openDialogSubject = new Subject<{groupName: string}>();
    openDialog$ = this.openDialogSubject.asObservable();
  
    constructor() { }
  
    openDialogModel(groupName: string,) {
      this.openDialogSubject.next({groupName});
    }
}
