import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-toggle-icon-button',
    templateUrl: './toggle-icon-button.component.html',
    styleUrls: ['./toggle-icon-button.component.css']
})
export class ToggleIconButtonComponent implements OnInit {
    @Input() selectedOption: string = '';
    @Input() toggleOptions: Array<any>;
    @Output() selectEvent = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void { }

    onToggle(value: string) {
        this.selectedOption = value;
        this.selectEvent.emit(value);
    }
}
