import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
 import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap} from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { ReportItem } from '../models/report-item';
import { RecentReviewListItem } from '../models/most-recent-reviewlist-item';
import { DataService } from '../services/data-service';
import { DialogService } from '../services/dialog.service';
import { WatchlistService } from '../services/watchlist.service';
import { IWeeklySummaryCardDetail } from '../models/iweekly-summary-card-detail';
import { PredictionGroupConfig } from '../models/prediction-group-config';
import { mostRecentPredictionDetails, predictionGroupConfigs } from 'src/assets/data/variableBag';
import { QuarterCalculatorService } from '../services/quarter-calculator.service';

interface IWeeklyDateRange {
    startDate: Date;
    endDate: Date;
    lastRunDate: Date;
}

@Component({
    selector: 'app-nav-summary',
    templateUrl: './nav-summary.component.html',
    styleUrls: ['./nav-summary.component.css'],
})

export class NavSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
    isLoading: boolean = true;
    predictionGroupConfigs: Array<PredictionGroupConfig>;
    quarter: number;
    reportsRoute$: BehaviorSubject<string>;
    subscription: Subscription = new Subscription();
    weeklySummaryCardDetails: Array<IWeeklySummaryCardDetail> = [];
    weeklyDateRange: IWeeklyDateRange = {
        startDate: new Date(),
        endDate: new Date(),
        lastRunDate: null,
    };

    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
        private dataService: DataService,
        private dialogService: DialogService,
        private watchlistService: WatchlistService,
        private quarterCalculatorService: QuarterCalculatorService,
        public router: Router,
    ) { }

    ngOnInit(): void {
        // init summaries
        this.initPredictionGroupConfigs();
        this.initWeeklySummaryCardDetails();
        this.initWeeklySummarySelectEvent(this.router.url);
        this.reportsRoute$ = new BehaviorSubject<string>(this.router.url);

        // update weekly summary card
        const summaryDataSub = this.reportsRoute$.pipe(
            switchMap(route => this.fetchSummaryData())
        ).subscribe(data => {
            const {watchlistClaims, underReviewClaims, weeklyDateRange, newPredictionReports} = data;
            this.weeklyDateRange = weeklyDateRange;
            this.quarter = this.calculateQuarter(weeklyDateRange.startDate);
            this.updateWeeklySummaryCardDetails(watchlistClaims.length, underReviewClaims.length, newPredictionReports[0].length, newPredictionReports[1].length,newPredictionReports[2].length);
            this.isLoading = false;
        });
        this.subscription.add(summaryDataSub);
    }

    ngAfterViewInit(): void {}

    // calculate quarter of the year based on date
    calculateQuarter(date: Date) {
        const currentDate = date;
        const quarter = this.quarterCalculatorService.getQuarter(currentDate);
        return quarter;
    }

    // get summary data from multiple collections
    fetchSummaryData() {
        const watchlist$ = this.getWatchlistByUID();
        const underReview$ = this.getUnderReviewByUID();
        const weeklyDateRange$ = this.getWeeklyDateRange(new Date());
        const newPrediction$ = this.predictionGroupConfigs.map(config => this.getNewPredictionReportsByGroup(config));

        return combineLatest([
            watchlist$,
            underReview$,
            weeklyDateRange$,
            ...newPrediction$
        ]).pipe(
            map(([watchlist, underReview, weekDate, ...newPredictions]) => {
                const watchlistClaims = watchlist as String[];
                const underReviewClaims = underReview as RecentReviewListItem[];
                const weeklyDateRange = weekDate as IWeeklyDateRange;
                const newPredictionReports = newPredictions as Array<ReportItem[]>;
                return {
                    watchlistClaims,
                    underReviewClaims,
                    weeklyDateRange,
                    newPredictionReports
                };
            })
        );
    }

    // get prediction group configs from the service
    initPredictionGroupConfigs() {
        this.predictionGroupConfigs = predictionGroupConfigs
            .map(config => {
                config.collectionPath = config.collectionPath.replace('<most-recent-collection-id>', this.watchlistService.mostRecentCollectionId);
                return config;
            });
    }

    // get most recent prediction card details from varible bag
    initWeeklySummaryCardDetails() {
        this.weeklySummaryCardDetails = mostRecentPredictionDetails;
        this.weeklySummaryCardDetails[2].name = this.predictionGroupConfigs[0].groupName;
        this.weeklySummaryCardDetails[3].name = this.predictionGroupConfigs[1].groupName;
        this.weeklySummaryCardDetails[4].name = this.predictionGroupConfigs[2].groupName;
    }

    // enable or disable select summary event
    initWeeklySummarySelectEvent(currentUrl: string) {
        if (currentUrl === '/reports/watchlist') {
            this.weeklySummaryCardDetails.forEach(detail => detail.disable = false);
        } else {
            this.weeklySummaryCardDetails.forEach(detail => detail.disable = true);
        }
    }
    
    // return a list of claim numbers from the current user's watchlist
    getWatchlistByUID(): Observable<string[] | null>  {
        const userUID = this.authService.userData.uid;
        return this.dataService.getMostRecentUserWatchlistItems(userUID);
    }

    // return reviewlist items from most recent reviewlist based on use roles
    getUnderReviewByUID(): Observable<RecentReviewListItem[] | null> {
        const userUID = this.authService.userData.uid;
        const userRoles = this.authService.userRoles;
        return this.dataService.getUnderReviewItems(userUID, userRoles);
    }

    // return a list of reports that newly flagged from last run based on user roles
    getNewPredictionReportsByGroup(predictionGroupConfig: PredictionGroupConfig): Observable<ReportItem[] | null> {
        const userRoles = this.authService.userRoles;
        const userData = this.authService.userData;
        return this.dataService.getNewPredictionGroupReports(predictionGroupConfig, userRoles, userData);
    }

    // get the last report from recent-claimant-reports sub collection
    getMostRecentClaimantReport() {
        return this.afs
            .collection(
                'most-recent',
                ref => ref.orderBy('createdAt', 'desc').limit(1),
            ).valueChanges();
    }

    // get weekly date range
    getWeeklyDateRange(currentDate: Date): Observable<IWeeklyDateRange> {
        const start = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
        const end = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()+6));
        let lastRun = null;
        return this.getMostRecentClaimantReport()
            .pipe(
                map(data => {
                    if (data[0]) {
                        const d = data[0] as any;
                        lastRun = d.updatedAt;
                    }              
                    return {
                        startDate: start,
                        endDate: end,
                        lastRunDate: lastRun
                    } as IWeeklyDateRange
                }),
            )
    }

    // pop up a table from watchlist
    openDialogTable(groupName: string) {
        this.dialogService.openDialogModel(groupName);
    }

    // data for weekly summary card
    updateWeeklySummaryCardDetails(watchlistClaimsCount: number, underReivewClaimsCount: number, predictionGroupCount10_50: number, predictionGroupCount75_150: number, predictionGroupCount225_399: number) {
        this.weeklySummaryCardDetails[0].count = watchlistClaimsCount;
        this.weeklySummaryCardDetails[1].count = underReivewClaimsCount;
        this.weeklySummaryCardDetails[2].count = predictionGroupCount10_50;
        this.weeklySummaryCardDetails[3].count = predictionGroupCount75_150;
        this.weeklySummaryCardDetails[4].count = predictionGroupCount225_399;
    }

    ngOnDestroy() {  
        this.subscription.unsubscribe();
    }

}
