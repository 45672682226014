import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

export interface ClaimantAdjustor {
    name: string;
    uid: string;
    orgGroups: string[];
}

let ADJUSTORS: ClaimantAdjustor[] = [];

@Injectable()
export class ClaimantAdjustors {
    private adjustors$: Observable<ClaimantAdjustor[]>;

    constructor(private afs: AngularFirestore) {
        this.adjustors$ = this.queryAdjustorUsers()
        .pipe(
            map(users => {
                ADJUSTORS = users.map(user => ({ name: user['fullname'], uid: user['uid'], orgGroups: user['orgGroups'] }));
                this.sortAdjustorList('ascending');
                return ADJUSTORS;
            }),
            shareReplay(1) // cache the result to avoid multiple requests
        );
    }
  
    getClaimantAdjustors(): Observable<ClaimantAdjustor[]> {
        return this.adjustors$;
    }

    queryAdjustorUsers(): Observable<any[]> {
        return this.afs
            .collection(
                'users',
                ref => ref.where('roles.adjustor', '==', true)
                          .where('roles.developer', '==', false)
            ).get()
            .pipe(
                take(1),
                map(result => result.docs.map(user => user.data())),
            );
    }

    /**
     * Sort menuitems in ascending or descending order based on key, default sorted by name
     * @param {string} order - Sort in "ascending" or "descending" order 
     * @param {string} [sortBy='name'] - Key from menuitems
     */
    sortAdjustorList(order: string, sortBy: string = 'name') {
        if (order === 'ascending') {
            ADJUSTORS.sort((a, b) => a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? -1 : a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : 0);
        } else {
            ADJUSTORS.sort((a, b) => a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? -1 : a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? 1 : 0);
        }  
    }
}
