import { Injectable } from '@angular/core';

import { Observable, of, Subject, Subscription } from 'rxjs';
import { 
    map,
    switchMap,
    tap,
    take,
    mergeAll,
    mergeMap,
} from 'rxjs/operators';

import { 
    AngularFirestore,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class LossReserveAuthorityService {
    adjustors = [];
    adjustorLossReserve$: Subject<any> = new Subject();
    allCounts = {
        total: 0,
        total75Q: 0,
    };
    selectedMetric = {
        total: 0,
        total75Q: 0,
    };

    constructor(
        private afs: AngularFirestore,
    ) { 
        // this.afs.collection(`adjustors`)
        // .valueChanges({idField: 'id' })
        // .pipe(
        //     take(1),
        //     map(adjustors => adjustors.map(adjustor => adjustor.id)),
        //     mergeAll(),
        //     mergeMap((id: any) => {
        //         return this.afs.collection(`adjustors/${id}/claimant-reports`).valueChanges();
        //     }),
        //     tap(claimantReports => {
        //         console.log('LOSS RESERVE AUTH :: ADJUSTOR REPORTS TAP');
        //         console.log(claimantReports);
        //         console.log('LOSS RESERVE AUTH :: ADJUSTOR REPORTS TAP');
        //     }),
        //     map(results => {
        //         this.updateAdjustorCounts(results);
        //         this.updateAllCounts();
        //         return results;
        //     }),
        //     tap(results => {}),
        // ).subscribe(
        //     r => {
        //         console.log('******* ALL ADJUSTOR METRICS ******* *******');
        //         console.log(this.adjustors);
        //         console.log('******* ALL ADJUSTOR METRICS ******* *******');
        //         this.adjustorLossReserve$.next(r);
        // });
    }

    initAdjustorLossReserveData() {
        return this.afs.collection(`adjustors`)
            .valueChanges({idField: 'id' })
            .pipe(
                take(1),
                map(adjustors => adjustors.map(adjustor => adjustor.id)),
                mergeAll(),
                mergeMap((id: any) => {
                    return this.afs.collection(`adjustors/${id}/claimant-reports`).valueChanges();
                }),
                tap(claimantReports => {
                    console.log('LOSS RESERVE AUTH :: ADJUSTOR REPORTS TAP');
                    console.log(claimantReports);
                    console.log('LOSS RESERVE AUTH :: ADJUSTOR REPORTS TAP');
                }),
                map(results => {
                    if (results.length > 0) {
                        this.updateAdjustorCounts(results);
                        this.updateAllCounts();
                    }
                    return results;
                }),
                tap(results => {}),
            )
    }

    selectAdjustor(uid) {
        let selectedAdjustor = {
            total: 0,
            total75Q: 0,
        };

        const adjustorFilter = this.adjustors.filter(adjustor => adjustor.uid === uid);
        if(adjustorFilter.length > 0) {
            this.selectedMetric = adjustorFilter[0];
            selectedAdjustor = adjustorFilter[0];    
        }
        return selectedAdjustor;
    }

    updateAllCounts() {
        this.allCounts.total = 0;
        this.allCounts.total75Q = 0;
        this.adjustors.forEach(adjustor => {
            this.allCounts.total = this.allCounts.total + adjustor.total;
            this.allCounts.total75Q = this.allCounts.total75Q + adjustor.total75Q;
        });
    }

    updateAdjustorCounts(adjustorReports) {
        let count = adjustorReports.length;
        const uid = adjustorReports[0]['uid'];
        let lossReserves = adjustorReports.map(result => Math.round( parseInt(result['claimantInfo']['lossReserve']) ) );
        lossReserves = lossReserves.sort((a, b) => a - b);
        const indexQ75 = lossReserves.length * 0.75;
        const lossReserves75q = lossReserves.slice(indexQ75, lossReserves.length + 1);

        let datum = {
            uid: uid,
            total: lossReserves.length,
            total75Q: lossReserves75q.length,
        };
        
        const uids = this.adjustors.map(adjustor => adjustor.uid);
        if (!uids.includes(uid)) {
            this.adjustors.push(datum);
        }
    }

}
