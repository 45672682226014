export const originalInjuries = {
    "alleged sexual misconduct": "Alleged Sexual Misconduct",
    "amputation": "Amputation",
    "brain major": "Brain - Major",
    "brain minor": "Brain - Minor",
    "burns major": "Burns - Major",
    "burns minor": "Burns - Minor",
    "fatal": "Fatal",
    "food poisoning": "Food Poisoning",
    "fracture major": "Fracture - Major",
    "fracture minor": "Fracture - Minor",
    "head no perm": "Head, No Perm.",
    "internal major": "Internal - Major",
    "internal minor": "Internal - Minor",
    "joint major": "Joint - Major",
    "joint minor": "Joint - Minor",
    "lacerations other": "Lacerations Other",
    "lacerations significant": "Lacerations Significant",
    "loss of consortium": "Loss Of Consortium",
    "not injuried": "Not Injuried",
    "other": "Other",
    "other dentel": "other dental",
    "personal advertising injury": "Personal/Advertising Injury",
    "psychological emotional": "Psychological/Emotional",
    "quad para": "Quad/Para.",
    "respiratory": "Respiratory",
    "sensory major": "Sensory - Major",
    "sensory minor": "Sensory - Minor",
    "soft tissue major": "Soft Tissue Major",
    "soft tissue minor": "Soft Tissue Minor",
    "spinal surgery": "Spinal Surgery",
    "spinal surgery major": "Spinal Surgery - Major",
    "spinal surgery minor": "Spinal Surgery - Minor",
    "tmj": "TMJ",
    "unknown": "Unknown"
}