import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWeeklySummaryCardDetail } from '../models/iweekly-summary-card-detail';


@Component({
    selector: 'app-weekly-summary-card',
    templateUrl: './weekly-summary-card.component.html',
    styleUrls: ['./weekly-summary-card.component.css']
})
export class WeeklySummaryCardComponent implements OnInit {
    @Input() title: string;
    @Input() details: Array<IWeeklySummaryCardDetail>;
    @Output() selectEvent = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void { }

    select(name: string): void {
        this.selectEvent.emit(name);
    }

}
