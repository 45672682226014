<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
 <div class="user-profile upper-left-box">
    <!-- User profile image -->
    <div class="profile-img"></div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    
    <app-nav-summary></app-nav-summary>

    <!-- <div class="profile-text"><a [matMenuTriggerFor]="sdprofile" class=""> Today's Run <i class="ti-angle-down font-12 m-l-5"></i></a></div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu">
        <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings </button>
        <button mat-menu-item>
            <mat-icon>account_box</mat-icon> Profile </button>
        <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon> Disable notifications </button>
        <button mat-menu-item>
            <mat-icon>exit_to_app</mat-icon> Sign Out </button>
    </mat-menu> -->
    
</div>
<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="active" group="{{menuitem.state}}">
        <!-- <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'"> -->
        <!-- <a [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'"> -->
        <a routerLink="" (click)="navigateTo(menuitem.state)" *ngIf="menuitem.type === 'link'">
            <mat-icon class="">{{ menuitem.icon }}</mat-icon> 
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> 
        </a> 
    </mat-list-item>
    <p class="sidebar-footer"> Workflows </p>
</mat-nav-list>

<style>
    .sidebar-footer {
        text-align: center;
        font-size: 10px;
        color: dimgray;
        font-weight: lighter;
        /* position: absolute;
        bottom: 0; */
    }
</style>