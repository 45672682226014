import { IUserRoles } from "./user-roles.interface";

export class UserRoles implements IUserRoles {
    adjustor: boolean;
    admin: boolean;
    developer: boolean;
    manager: boolean;
    superManager: boolean
    supervisor: boolean;
    trial: boolean;

    constructor(roles: IUserRoles) {
        this.adjustor = roles.adjustor;
        this.admin = roles.admin;
        this.developer = roles.developer;
        this.manager = roles.manager;
        this.superManager = roles.superManager;
        this.supervisor = roles.supervisor;
        this.trial = roles.trial;
    }
}
