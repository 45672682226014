<div class="table-header">

    <!-- table action buttons -->
    <div class="table-action-buttons">
        <app-button *ngFor="let button of actionButtons;"
            [buttonId]="button.buttonId"
            [buttonText]="button.buttonText"
            [buttonIcon]="button.buttonIcon"
            [buttonType]="button.buttonType"
            [isDisabled]="disableActionButton(button)"
            [highlightButton]="button.highlightButton"
            [multipleFile]="button.multipleFile"
            (clickEvent)="clickButton($event)">
        </app-button>
        <!-- <button mat-icon-button *ngFor="let button of actionButtons;"
            class="table-action-button"
            [attr.aria-label]="button.buttonText"
            [matTooltip]="(button.buttonText | capitalize)">
            <mat-icon class="table-icon-button" (click)="clickButton(button.buttonId)">{{button.buttonIcon}}</mat-icon>
        </button> -->
    </div>

    <!-- table labels -->
    <div class="table-indicator-labels">
        <label class="required-column-label">
            <span class="required-column-color">*</span> 
            Required Field
        </label>
        <label class="required-column-label">
            <mat-icon class="read-only-column-icon">lock</mat-icon> 
            Read-Only
        </label>
    </div>
</div>

<!-- table container -->
<div class="table-container">
    <table mat-table [dataSource]="dataSource" class="table">
        
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="select-column-header">
                <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [disabled]="dataSource.length === 0">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" class="select-column-cell">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(element) : null"
                    [checked]="selection.isSelected(element)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Dynamic Columns -->
        <ng-container *ngFor="let columnName of displayedColumns; let columnIndex = index;" [matColumnDef]="columnName">
            <th mat-header-cell *matHeaderCellDef>
                {{columnName}}
                {{isColumnReadOnly(columnName) ? '' : columnConfigs[columnName].required ? '*' : '(optional)'}}
                <mat-icon *ngIf="isColumnReadOnly(columnName)" class="read-only-column">lock</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element; let rowIndex = index;"
                matTooltipPosition="right"
                [matTooltip]="getInvalidMessage(columnName, columnIndex, rowIndex)"
                [attr.contenteditable]="isCellEditable(columnName)"
                (click)="onCellClick(columnName, columnIndex, rowIndex)"
                (blur)="onCellBlur($event, columnName, columnIndex, rowIndex)"
                (keydown.enter)="onCellKeyEnter($event)">
                <!-- {{element[columnName]}} -->

                <!-- dropdown cell -->
                <ng-container *ngIf="isDropdownColumn(columnName); else textCell">
                    <mat-select
                        class="dropdown-cell"
                        [class.invalid-dropdown-cell]="isCellInvalid(columnName, columnIndex, rowIndex)"
                        [(value)]="element[columnName]"
                        (selectionChange)="onDropdownChange($event, columnName, columnIndex, rowIndex)"
                        placeholder="Select an option">
                        <mat-option *ngFor="let option of dropdownColumnConfigs[columnName]" [value]="option.value">
                            {{ option.viewValue }}
                        </mat-option>
                    </mat-select>
                </ng-container>
                
                <!-- text cell -->
                <ng-template #textCell>
                    <div
                        class="text-cell"
                        [class.invalid-cell]="isCellInvalid(columnName, columnIndex, rowIndex)"
                        [class.selected-cell]="isCellSelected(columnIndex, rowIndex)"
                        [innerText]="element[columnName]">
                    </div>
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsWithSelect; sticky: true" class="table-row"></tr>
        <tr #row mat-row *matRowDef="let row; columns: displayedColumnsWithSelect;  let rowIndex = index" class="table-row"
            [id]="rowIndex"
            [class.selected-row]="selection.isSelected(row)">
        </tr>
    </table>
</div>