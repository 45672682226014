<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="box">
        <mat-card-header>
            <mat-card-title *ngIf="disableVerification">Log in</mat-card-title>
            <div class="enrollment-header-container" *ngIf="!disableVerification">
                <mat-card-title>MFA Enrollment</mat-card-title>
                <div matTooltip={{enrollToolInfo}}
                matTooltipPosition="right" 
                matTooltipShowDelay="533"
                class="info-icon-container">
                    <mat-icon class="info-icon">info</mat-icon>
                </div>
            </div>
        </mat-card-header>

        <form class="form" [formGroup]="loginForm">
            <mat-card-content class="card-content" *ngIf="disableVerification">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="somebody@example.com" formControlName="username">
                    <mat-error *ngIf="formControls.username.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline" hintLabel="Minimum 6 characters">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" minlength="6" placeholder="Password" formControlName="password">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </mat-card-content>
            <div class="enrollment-block" *ngIf="!disableVerification">
                <qrcode
                    [qrdata]="QrCodeVal"
                    [allowEmptyString]="true"
                    [cssClass]="'center'"
                    [colorDark]="'#000000ff'"
                    [colorLight]="'#ffffffff'"
                    [elementType]="'canvas'"
                    [errorCorrectionLevel]="'M'"
                    [width]="300">
                </qrcode>
                <div class="secret-container">
                    <mat-card-title class="secret-code-block">SecretCode:</mat-card-title>
                    <div class="secret-code-block-1">{{this.textVal}}</div>
                </div>
                <mat-card-content class="card-content" *ngIf="!disableVerification">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>MFA Enroll Verification</mat-label>
                        <input matInput placeholder="enter verification code" formControlName="verification">
                    </mat-form-field>
                </mat-card-content>
            </div>
            <div class="login-block">
                <mat-card-content class="card-content" *ngIf="!loggedIn">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>ToTp Code</mat-label>
                        <input matInput placeholder="enter totp code" formControlName="totpcode">
                    </mat-form-field>
                </mat-card-content>
            </div>
            <button mat-raised-button color="basic" class="btn-block" (click)="completeEnrollment()" *ngIf="!disableVerification">Complete Verification</button>
            <button mat-raised-button color="basic" class="btn-block" (click)="totpVerify()" *ngIf="!loggedIn">enter secret code</button>
            <button mat-raised-button color="basic" class="btn-block" (click)="login()" *ngIf="disableVerification && loggedIn">Log in</button>
        </form>
    </mat-card>
</div>
