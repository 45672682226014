<button mat-button *ngIf="buttonType === 'button'"
    class="button"
    [id]="buttonId"
    [disabled]="isDisabled"
    [ngClass]="{'background-button': highlightButton}"
    (click)="clickButton(buttonId, null)">
    <mat-icon class="button-icon" *ngIf="buttonIcon">{{buttonIcon}}</mat-icon>
    {{buttonText | titlecase}}
</button>

<!-- file type -->
<button mat-button *ngIf="buttonType === 'file'"
    class="button"
    [id]="buttonId"
    [disabled]="isDisabled"
    [ngClass]="{'background-button': highlightButton}"
    (click)="fileInput.click()">
    <mat-icon class="button-icon" *ngIf="buttonIcon">{{buttonIcon}}</mat-icon>
    {{buttonText | titlecase}}
    <input #fileInput hidden type="file"
        [multiple]="multipleFile"
        (change)="selectFiles(buttonId, $event)"/>
</button>