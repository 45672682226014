import { Injectable } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { HelperService } from 'src/app/services/helper-service';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

let MENUITEMS = [
  // { state: 'lae', type: 'link', name: 'Analytical Tools', icon: 'fact_check' },
  // { state: 'clq', type: 'link', name: 'CLQ Calculator', icon: 'fact_check' },
  { state: 'reports', type: 'link', name: 'Bodily Injury Claims', icon: 'folder_shared' },
  { state: 'data-management', type: 'link', name: 'Data Management', icon: 'folder' },
  { state: 'adjustors', type: 'link', name: 'Adjustor Performance', icon: 'fact_check' },
  { state: 'major case review', type: 'link', name: 'Major Case Review', icon: 'groups' },
];

@Injectable()
export class MenuItems {
  constructor(private authService: AuthService) {
    if (this.authService.userRoles.trial || this.authService.userRoles.admin) {
    //   MENUITEMS.push({ state: 'claimant-details', type: 'link', name: 'Report Generator', icon: 'text_snippet' });
    //   MENUITEMS.push({ state: 'lae', type: 'link', name: 'Analytical Tools', icon: 'fact_check' });
    }
  }
  
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
