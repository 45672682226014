import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export class areaChartCacheItem{
    county:string;
    day1:number;
    day2:number;
    day3:number;
    injury:string;
    result_1:number;
    result_2:number;
    result_3:number;
    state:string;
}