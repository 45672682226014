
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { firebaseConfig } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { CurrencyPipe } from '@angular/common';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { QRCodeModule } from 'angularx-qrcode';
import {
  CanvasRenderer
} from 'echarts/renderers';
import Marcaron from './marcaron';

import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { getAnalytics } from "firebase/analytics";
echarts.use(
    [
        TitleComponent,
        TooltipComponent,
        GridComponent,
        LineChart,
        CanvasRenderer,
    ]);
echarts.registerTheme('macarons', Marcaron);


//Services
import { AuthService } from './core/auth.service';
//Components
import { LoginComponent } from './login/login.component';
import { locally } from '../assets/extras/localh';
import { environment, recaptchav3 } from '../environments/environment';
import { NavSummaryComponent } from './nav-summary/nav-summary.component';
import { LossReserveAuthorityComponent } from './loss-reserve-authority/loss-reserve-authority.component';
import { LoadScreenModule } from '../app/load-screen/load-screen.module';
import { provideFirebaseApp, initializeApp } 
from '@angular/fire/app';
import { getAuth, provideAuth } 
from '@angular/fire/auth';
import { getFirestore, provideFirestore } 
from '@angular/fire/firestore';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { WeeklySummaryCardComponent } from './weekly-summary-card/weekly-summary-card.component';

function appCheckDebug() {
    // (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        FullComponent,
        AppHeaderComponent,
        SpinnerComponent,
        AppSidebarComponent,
        NavSummaryComponent,
        WeeklySummaryCardComponent,
        LossReserveAuthorityComponent,
    ],
    imports: [
        ReactiveFormsModule,
        AngularMaterialModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        LoadScreenModule,
        // FlexLayoutModule,
        QRCodeModule,
        provideAppCheck(() => {
            if (locally.develop) {
                (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = false;    
            }
            return initializeAppCheck(undefined, {
                provider: new ReCaptchaV3Provider(recaptchav3.siteKey),
                isTokenAutoRefreshEnabled: true,
            });
        }),
        provideFirebaseApp(
          () => {
            const app = initializeApp(firebaseConfig);
            const analytics = getAnalytics(app);
            return app;
          }), 
            provideAuth(() => getAuth()), 
            provideFirestore(() => getFirestore()
        ),
        HttpClientModule,
        SharedModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        RouterModule.forRoot(AppRoutes)
    ],
    providers: [
        AuthService,
        CurrencyPipe,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: FIREBASE_OPTIONS, 
            useValue: firebaseConfig
        }
        // {
        //     provide:  APP_INITIALIZER,
        //     useFactory: () => appCheckDebug,
        //     multi: true
        // }
        
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
