<div class="body-container">
    <div class="body-content">
        <h4>{{title}}</h4>
        <h5>{{message}}</h5>
    </div>
</div>

<div class="action-buttons-container">
    <div class="action-button-sub-container"
        *ngFor="let button of buttons">
        <button mat-button 
            class="action-button"
            [class.confirm-button]="button === confirmButton"
            aria-label="action"
            (click)="confirm(button)">
            {{button | titlecase}}
        </button>
    </div>
</div>