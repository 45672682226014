export const paidlossAdjustedInjuryRanking = {
    'Not Found': 0,
    'Unknown': 0,
    'Other': 0,
    'Soft Tissue - No Medical Treatment data': 3,
    'Sensory - Minor': 3.5,
    'Sensory - Major': 12,
    'Soft Tissue - Minor': 4,
    'Soft Tissue - Major': 10,
    'Lacerations - No Medical Treatment data': 1,
    'Lacerations Other': 3,
    'Lacerations Significant': 13,
    'Fracture - No Medical Treatment data': 6,
    'Fracture - Minor': 7,
    'Fracture - Major': 17,
    'Head, No Perm. - No Medical Treatment data': 5,
    'Head, No Perm.': 6,
    'Psychological/Emotional': 6.5,
    'Joint - No Medical Treatment data': 8,
    'Joint - Minor': 9,
    'Joint - Major': 16,
    'Internal - No Medical Treatment data': 11,
    'Internal - Minor': 12,
    'Internal - Major': 18,
    'Spinal - No Medical Treatment data': 19,
    'Spinal - Minor': 20,
    'Spinal - Major': 21,
    'Brain - No Medical Treatment data': 14,
    'Brain - Minor': 15,
    'Brain - Major': 22,
}
