import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    public authService: AuthService,
    public router: Router
  ) { 
    this.authService.loadLocalStorage();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // console.log('func: canLoad');
    // const expired = this.authService.isTokenExpired();
    // console.log(`Token has expired?: ${expired}`);
    // return expired ? this.router.navigate(['login']) : true;
    if (this.authService.isLoggedIn !== true) {
      this.router.navigate(['login']);
    }
    return true;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const userRoles = this.authService.userRoles;
      const userRolesList = Object.keys(userRoles).filter(i => userRoles[i]);
      if (this.authService.isLoggedIn) {
        if (next.data.roles && userRolesList.filter(r => next.data.roles.includes(r)).length < 1 ) {
          const root = state.url.toString().split('/')[1];
          // this.router.navigate([`/${root}`]);
          this.router.navigate([`/`]);
          return false;
        }
        return true;
      }
      this.router.navigate(['login']);
      return false;
  }
  
}
