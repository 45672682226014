import { Injectable } from '@angular/core';

import { locally } from '../../assets/extras/localh'; //'../assets/extras/localh';


@Injectable({
  providedIn: 'root'
})
export class ConsoleToggleService {

    constructor() { }

    disableConsoleWhenNotDemo(): void {
        if (!locally.develop) {
            console.warn(`🚨 Console output is disabled outside of demo and dev!`);
            console.log = function(): void{}; 
            console.debug = function(): void{};
            console.warn = function(): void{};
            console.info = function(): void{};
        }
    }
}
