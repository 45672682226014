import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { tableActionButtons } from 'src/assets/data/variableBag';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnChanges {
    @Input() contentEditable: boolean = false;
    @Input() columnConfigs: any;
    @Input() dataSource: any[] = [];
    @Input() defaultColumnValues: any = {};
    @Input() displayedColumns: string[] = [];
    @Input() dropdownColumnConfigs: any = {};
    @Input() invalidCells: any;
    @Input() rowLimit: number;
    @Input() readOnlyColumns: any[] = [];
    @Output() updateCellEvent = new EventEmitter<any>();
    @Output() updateDataSourceEvent = new EventEmitter<any>();
    @ViewChildren('row', {read: ElementRef}) rows!: QueryList<ElementRef<HTMLTableRowElement>>;
    actionButtons: any[] = tableActionButtons;
    displayedColumnsWithSelect: string[] = [];
    selectedCell: any = {cell: '', columnIndex: null, rowIndex: null};
    selection: SelectionModel<any> = new SelectionModel<any>(true, []);

    constructor(
        private _snackBar: MatSnackBar,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.displayedColumnsWithSelect = ['select', ...this.displayedColumns];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSource && !changes.dataSource.firstChange) {
            this.selection.clear();
        }
    }

    addRow() {
        const newRow = this.displayedColumns.reduce((acc, column) => {
            acc[column] = '';
            if (column in this.defaultColumnValues) {
                acc[column] = this.defaultColumnValues[column];
            }
            return acc;
        }, {});
        this.dataSource = [...this.dataSource, newRow];
        this.updateDataSource(this.dataSource);
        
        // Scroll to the new row index
        setTimeout(() => {
            const rowIndex = this.dataSource.length - 1;
            this.scrollToRow(rowIndex);
        }, 100);
    }

    clickButton(event) {
        const {id, files} = event;
        if (id === this.actionButtons[0].buttonId) {
            this.addRow();
        } else if (id === this.actionButtons[1].buttonId) {
            this.deleteRows();
        } 
    }

    deleteRows() {
        const data = {
            title: '',
            message: `Are you sure you want to delete ${this.selection.selected.length} row(s) from the table?`,
            buttons: ['cancel', 'delete'],
            confirmButton: 'delete'
        };
        const dialogRef = this.openConfirmationDialog(data);
        dialogRef.afterClosed().subscribe(result => {
            if (result.confirm) {
                const rowSelectedCount = this.selection.selected.length;
                this.deleteSelectedRows();
                this.updateDataSource(this.dataSource);
                this._snackBar.open(`Deleted ${rowSelectedCount} row(s)`, 'X', { duration: 3000 });
            }
        });
    }

    deleteSelectedRows() {
        const selectedRows = this.selection.selected;
        this.dataSource = this.dataSource.filter(row => !selectedRows.includes(row));
        this.selection.clear();
    }

    disableActionButton(button) {
        if (button.buttonId === this.actionButtons[0].buttonId) {
            return this.selection.selected.length > 0 ? true : 
                    this.dataSource.length >= this.rowLimit ? true : button.isDisabled;
        } else if (button.buttonId === this.actionButtons[1].buttonId) {
            return this.selection.selected.length === 0 ? true : button.isDisabled;
        } else {
            return button.isDisabled;
        }
    }

    getInvalidMessage(columnName: string, columnIndex: number, rowIndex: number) {
        const invalidCell = this.isCellInvalid(columnName, columnIndex, rowIndex);
        const key = `${rowIndex}-${columnName}`;
        if (!invalidCell) {
            return '';
        }
        return this.invalidCells[key][1];
    }

    /* Checks if the number of selected elements matches the total number of rows. */
    isAllSelected() {
        if (this.selection.selected.length === 0) {
            return false;
        }
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.length;
        return numSelected === numRows;
    }

    isCellEditable(columnName: string) {
        const dropdownColumns = Object.keys(this.dropdownColumnConfigs);
        if (this.isColumnReadOnly(columnName)) {
            return false;
        // user can select an option from the dropdown but not entering text
        } else if (dropdownColumns.includes(columnName)) {
            return false;
        } else if (this.contentEditable) {
            return true;
        } else {
            return false;
        }
    }

    isCellInvalid(columnName: string, columnIndex: number, rowIndex: number) {
        const key = `${rowIndex}-${columnName}`;
        if (!this.invalidCells.hasOwnProperty(key)) {
            return false;
        }
        if (this.columnConfigs[columnName].required == false && this.dataSource[rowIndex][columnName] == "" || this.dataSource[rowIndex][columnName] == undefined) {
            return false;
        }
        return this.invalidCells[key][0];
    }

    isCellSelected(columnIndex: number, rowIndex: number) {
        if (this.selectedCell.cell === `c${columnIndex}r${rowIndex}`) {
            return true;
        } else {
            return false;
        }
    }

    isColumnReadOnly(columnName: string) {
        if (this.readOnlyColumns.includes(columnName)) {
            return true;
        } else {
            return false;
        }
    }

    isDropdownColumn(columnName: string) {
        if (this.dropdownColumnConfigs[columnName]) {
            return true;
        } else {
            return false;
        }
    }

    /* Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.forEach(row => this.selection.select(row));
    }

    onCellBlur(event: any, columnName: string, columnIndex: number, rowIndex: number) {
        const newValue = event.target.innerText.replace('\n', '');
        const oldValue = this.dataSource[rowIndex][columnName];
        this.selectedCell = {};
        if ((oldValue && newValue.toString() !== oldValue.toString()) || !oldValue && newValue ) {
            this.dataSource[rowIndex][columnName] = newValue ? newValue.trim() : '';
            this.dataSource = [...this.dataSource];
            this.updateCell(newValue, columnName, columnIndex, rowIndex);
        }
    }

    onCellClick(columnName: string, columnIndex: number, rowIndex: number) {
        if (!this.isCellEditable(columnName)) {
            return;
        }
        this.selectedCell.cell = `c${columnIndex}r${rowIndex}`;
        this.selectedCell.columnIndex = columnIndex;
        this.selectedCell.rowIndex = rowIndex;    
    }

    onCellKeyEnter(event: KeyboardEvent) {
        event.preventDefault();
        (event.target as HTMLElement).blur();
    }

    onDropdownChange(event, columnName: string, columnIndex: number, rowIndex: number) {
        const newValue = event.value;
        this.dataSource[rowIndex][columnName] = newValue;
        this.updateCell(newValue, columnName, columnIndex, rowIndex);
    }

    openConfirmationDialog(data) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.width = '25%';
        dialogConfig.height = '25%';
        dialogConfig.data = data;
        return this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    }
    
    scrollToRow(rowIndex: number): void {
        let elem = this.rows.find(row => row.nativeElement.id === rowIndex.toString());
        elem?.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
    }

    updateCell(value: string, columnName: string, columnIndex: number, rowIndex: number) {
        this.updateCellEvent.emit({
            value: value,
            cell: `c${columnIndex}r${rowIndex}`,
            columnName: columnName,
            columnIndex: columnIndex,
            rowIndex: rowIndex,
        });
    }

    updateDataSource(dataSource: any[]) {
        // // Verify cells when row get added
        // const rowIndex = this.dataSource.length - 1;
        // const newRow = dataSource[rowIndex];
        // let newRowColumns = Object.keys(newRow);
        // newRowColumns.forEach((columnName, columnIndex) => {
        //     const columnValue = this.dataSource[rowIndex][columnName];
        //     this.updateCell(columnValue, columnName, columnIndex, rowIndex);
        // });
        this.updateDataSourceEvent.emit({data: dataSource});
    }

}
