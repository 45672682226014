import firebase from 'firebase/compat/app';
import { paidlossAdjustedInjuryRanking } from 'src/assets/data/injuryCodeRanking';

export class InjuryPredictionResult {
    claimNumber: number | string;
    claims: Array<string>;
    clmtList: Array<string> | string;
    createdAt: firebase.firestore.Timestamp;
    minimumInjuryPredicted: string;
    targets: Array<any>;
    updatedAt: firebase.firestore.Timestamp;

    constructor() { }

    parseClaims(): Array<string> {
        const claims = [];
        const claimNumber = String(this.claimNumber);
        if (!(this.clmtList instanceof Array)) {
            const clmtListArr = String(this.clmtList).replace('[', '').replace(']', '').split(",");
            clmtListArr.forEach(clmt => claims.push(`${claimNumber}-${clmt.trim()}`));
        } else if (this.clmtList instanceof Array) {
            this.clmtList.forEach(clmt => claims.push(`${claimNumber}-${clmt.trim()}`));
        }
        return claims;
    }

    parseInjuryByDuration(duration: string): string {
        let injury = '';
        this.targets.forEach(item => {
            if (item['duration'] === duration) {
                injury = item['prediction'];
            }
        });
        return injury;
    }

    parseMinimumInjuryPredicted(): string {
        const leastScoreInjury = {
            duration: this.targets[0]['duration'],
            injury: this.targets[0]['prediction'],
            score: paidlossAdjustedInjuryRanking[this.targets[0]['prediction'].trim()]
        };
        this.targets.forEach(item => {
            const duration = item['duration'];
            const injury = item['prediction'];
            if (injury) {
                const score = paidlossAdjustedInjuryRanking[injury.trim()];
                if (score < leastScoreInjury.score) {
                    leastScoreInjury.duration = duration;
                    leastScoreInjury.injury = injury;
                    leastScoreInjury.score = score;
                }
            }  
        });
        return leastScoreInjury.injury
    }
}
