<div class="weekly-summary-card-container">
    
    <!-- summary header -->
    <label class="weekly-summary-card-header">{{title | titlecase}}</label>
    <mat-divider></mat-divider>

    <!-- summary details -->
    <label class="weekly-summary-card-detail"
        *ngFor="let detail of details; let i = index;"
        [ngClass]="detail.disable ? 'disable-select-event' : ''"
        (click)="select(detail.name)">
        {{ detail.name | titlecase }}
        <span class="weekly-summary-card-detail-count">{{ detail.count }}</span>
    </label>

</div>
