import firebase from 'firebase/compat/app';

export class AuditClaimantReportItem {

    constructor() {
        this.auditedAttributes = {
            'lossReserve': 0,
            'injuryCode': '',
            'predictionMin': 0,
            'lae': {},
          };
    }
    claim:string;
    auditlistId: string;
    createdAt: firebase.firestore.Timestamp;
    updatedAt: firebase.firestore.Timestamp;
    auditClaimantReportId:string;
    verifiedBy:string;
    reviewStartedAt: firebase.firestore.Timestamp;
    adjustor: string;
    office: string;
    auditedAttributes: {
        'lossReserve':number,
        'injuryCode':string,
        'predictionMin':number,
        'lae': Record<string, string | number>;
    }

    toFirestoreObject(): Record<string, any> {
        return {
          claim: this.claim,
          auditlistId: this.auditlistId,
          createdAt: this.createdAt,
          updatedAt: this.updatedAt,
          verifiedBy: this.verifiedBy,
          reviewStartedAt: this.reviewStartedAt,
          adjustor: this.adjustor,
          office: this.office,
          auditedAttributes: {
            lossReserve: this.auditedAttributes.lossReserve,
            injuryCode: this.auditedAttributes.injuryCode,
            predictionMin: this.auditedAttributes.predictionMin,
            lae: this.auditedAttributes.lae,
          },
        };
      }
}