import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { AuthService } from '../core/auth.service';
import { GoogleAnalyticsService } from '../services/google-analytics-service';
import { HelperService } from '../services/helper-service';
import { Subject, Subscription, interval } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    hide = true;
    loggedIn = true;
    disableVerification = true;
    textVal:string = "";
    verificationVal:string = "";
    secretVal:string = "";
    QrCodeVal:string = "default";
    qrBool:boolean = false;
    subscription = new Subscription();
    enrollToolInfo:string = "scan qr code or enter the secret code in your google authenticator app";
    timerSubscribtion = new Subject();
    loginSubject = new Subject();
    stopTimerSubject: Subject<void> = new Subject<void>();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private authService: AuthService,
        private helperService: HelperService,
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService
    ) { }

    ngOnInit() {
        if (this.authService.isLoggedIn) {
            this.authService.loadLocalStorage();
            const userRoles = this.authService.userRoles;
            const userRolesList = Object.keys(userRoles).filter(i => userRoles[i]);
            const defaultPath = this.helperService.getDefaultReportPath(userRolesList);
            this.router.navigate([defaultPath]); //once logged in traverse to reports path
        }
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            verification: [''],
            totpcode: ['']
        });
        const userNameVal = localStorage.getItem('userNameStore');
        if (userNameVal) {
            this.formControls.username.setValue(userNameVal);
        }
        const enrollsub = this.authService.getSecretVal(
          ).subscribe((val) =>{
            if (val) {
            this.QrCodeVal = val['totpUri'];
            this.textVal = val['secret'];
            this.disableVerification = false;
            this.loggedIn = true;
          }});
        this.subscription.add(enrollsub);

        const errorsub = this.authService.getErrorMessage(
          ).subscribe((errorCode) => {
            if (errorCode) {
                this.openSnackBar(errorCode, 'X', { duration: 5000 });
                if (errorCode.toString().includes("Firebase: The SMS verification code used to create the phone auth credential is invalid")) {
                    this.stopTimerInterval();
                }
                console.log('recieved error code', errorCode);
            }
        });
        this.subscription.add(errorsub);

        const timerSub = this.timerSubscribtion.pipe(
            tap(()=>{
                this.openSnackBar('mfa enrollment completed, Now that your app is authenticated, please login again', 'X', { duration: 6000 });     
                const userNameStore = this.formControls.username.value;
                if(this.formControls.username.value) {
                    localStorage.setItem("userNameStore", userNameStore);
                }
            }),
            switchMap((eventType) =>
              interval(1000).pipe(
                takeUntil(this.stopTimerSubject),
                take(4),
                map((waitTime) => {
                  if (waitTime === 3) {//wait time given 3 secs
                    location.reload();
                    this.stopTimerInterval();
                  }
                })
              )
            )
        ).subscribe();
        this.subscription.add(timerSub);

        const loginSub = this.loginSubject.pipe(
            switchMap(() => {
                return this.authService.login(
                    this.formControls.username.value, 
                    this.formControls.password.value
                );
            })
          ).subscribe((res) => {
                if (!res['loggedIn'] && res['message'] == "Firebase: Proof of ownership of a second factor is required to complete sign-in. (auth/multi-factor-auth-required).") {
                    this.loggedIn = false;
                    this.openSnackBar(res['message'], 'X', { duration: 5000 });
                }
                if (!res['loggedIn'] && res['message'] == "triggered totp mfa registration") {
                    this.openSnackBar(res['message'], 'X', { duration: 5000 });
                }
                else {
                    this.openSnackBar(res['message'], 'X', { duration: 5000 });
                }
        });
        this.subscription.add(loginSub);
    }

    completeEnrollment() {
      this.verificationVal = this.formControls.verification.value;
      this.authService.enrollCompletion(this.verificationVal);
      this.startTimer();
    }

    totpVerify() {
      this.secretVal = this.formControls.totpcode.value;
      this.authService.triggerMfaVerification(this.secretVal);
    }

    get formControls() { 
        return this.loginForm.controls;
    }

    openSnackBar(message: string, action: string, option?: any) {
        this._snackBar.open(message, action, option);
    }

    getErrorMessage() {
        if (this.formControls.username.hasError('required')) {
          return 'You must enter a value';
        }
        return this.formControls.username.hasError('email') ? 'Not a valid email' : '';
    }

    login() {
        if (this.loginForm.invalid) {
            this.openSnackBar('Invalid username or password', 'X', { duration: 5000 });
            return;
        }
        this.googleAnalyticsService.sendEventToAnalytics('web_app_login','authentication', 'user logged in');
        this.loginSubject.next('trigger login');
    }

    startTimer(): void {
        this.timerSubscribtion.next(`enrollment completed!`);
    }

    stopTimerInterval() {
        this.stopTimerSubject.next(); 
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
