import { NgModule } from '@angular/core';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { AngularMaterialModule } from '../angular-material.module';
import { ButtonComponent } from './components/button/button.component';
import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { ClaimantAdjustors } from './claimant-adjustors/claimant-adjustors';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { TableComponent } from './components/table/table.component';
import { ToggleIconButtonComponent } from './components/toggle-icon-button/toggle-icon-button.component';


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ButtonComponent,
    CapitalizePipe,
    DateAgoPipe,
    TableComponent,
    ToggleIconButtonComponent,
  ],
  imports: [
    AngularMaterialModule,
    CommonModule,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ButtonComponent,
    CapitalizePipe,
    DateAgoPipe,
    TableComponent,
    ToggleIconButtonComponent,
   ],
  providers: [ MenuItems, ClaimantAdjustors ]
})
export class SharedModule { }
